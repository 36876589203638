/* eslint-disable sonarjs/no-duplicate-string */
import {
  admin,
  allorder,
  banner,
  campaign,
  catalogue,
  category,
  coinEarned,
  coinExchange,
  dashboard,
  hubStaff,
  fraudSetting,
  gwp,
  hub,
  inboundPo,
  inboundSo,
  location,
  loyaltyActivity,
  loyaltyVoucher,
  // productDiscount
  mpPlanning,
  orderDetail,
  // order,
  // orderPackerPicking,
  // orderPicker,
  packageIDGenerator,
  koliIDGenerator,
  product,
  brandMaster,
  promotion,
  purchaseOrderLite,
  purchaseOrderLiteApproval,
  directputaway,
  // purchaseRequest,
  rack,
  referralSetting,
  refund,
  reorderList,
  serviceLevel,
  setPosition,
  setting,
  supplyOrder,
  ticker,
  uom,
  warehouse,
  schedule,
  masterPayroll,
  bom,
  kitting,
  centralWarehouseStaff,
  supplyOrderApproval,
  uniqueVoucherList,
  uniqueVoucherCampaignList,
  loadingDock,
  timeSlot,
  fraudBlackAndWhiteList,
  stockOpnameList,
  bulkUploadV2,
  stockOpnameAdjustment,
  coinHistory,
  pwp,
  inboundSchedule,
  koliLost,
  referralMissionList,
  flagManagement,
  vendorBrandGrouping,
  fraudReferral,
  disbursement,
  promoReferralWidget,
  fraudWatchTowerRule,
  fraudWatchTowerQueryRule,
  fraudWatchTowerParameters,
  routeAndSchedule,
  productAssortment,
  locationPreview,
  productLabel,
  createOrder,
  campaignLoyalty,
  referralVIPList,
  skpSourcing,
  skpFinance,
  skpTermsAndConditions,
  dynamicLandingList,
  hcu,
  poolingMap,
  // driverMonitoring,
} from 'config/routes'
import { allowAccess } from 'middleware/privateRoute'
import { hubPageLocationType } from 'constant/locationType'
import {
  DynamicChannelRouter,
  HomepageSlottingRouter,
  PinProductRouter,
} from 'features/CategoryAndPosisi/router'
import {
  CampaignSegmentListRouter,
  CampaignAndDiscountRouterDetail,
} from 'features/CampaignAndDiscount/router'
import { TicketScannerRouter } from 'features/AstroEvent/router/'
import {
  BuyerRouter,
  AuthenticationControllRouter,
  AdminAccessRouter,
} from 'features/Account/router/'
import {
  allowedRolesReorderListSc,
  allowedRolesReorderListSourcing,
} from 'features/Enterprise/ReorderList/router'
import { LoyaltyRouterDetail } from 'features/Loyalty/router'
import { SupplyOrderRouterDetail } from 'features/SupplyOrder/router'
import {
  FraudRouterDetail,
  UserFraudStatusRouter,
  WatchtowerAreaDetectionRouter,
  WatchTowerAutoCancelRuleListRouter,
} from 'features/Fraud/router'
import { ProductRouterDetail } from 'features/Product/router'
import { ApprovalInjectionRouter, PaymentGatewayAdminRouter } from 'features/Payment/router'
import { OrderRouterDetail, OnGoingRoutes } from 'features/Order/router'
import { PromotionRouterDetail, MilestoneListRouter } from 'features/Promotion/router'
import { BannerThematicRouter, BannerRouter } from 'features/BannerAndTicker/router'
import { VariantModuleRouter } from 'features/Product/router/router'
import { MPBudgetRouter, MasterPayrollRouter } from 'features/Enterprise/HRIS/router'
import { InternalSKPRouter, CampaignRouter } from 'features/Enterprise/SKP/router'
import { VendorListRouter } from 'features/Enterprise/Vendor/router'
import * as SOAutomationRouter from 'features/Enterprise/SOAutomation/router'
import { ABAdminRouterDetail } from 'features/ABAdmin/router'
import {
  ScheduleRouter,
  AttendanceRouter,
  MasterApprovalRouter,
  MasterShiftRouter,
  combineScheduleRolesAccess,
} from 'features/Enterprise/Schedules/router'
import { MPStaffRouter } from 'features/Enterprise/Account/router'

import { locationRBAC } from 'pages/Location/rbac'
import { locationPreviewRBAC } from 'pages/LocationPreview/rbac'
import { featureFlagRBAC } from 'pages/FlagManagement/rbac'
import { serviceLevelRBAC } from 'pages/ServiceLevel/rbac'
import { PoolingMapRolesRBAC } from 'features/Order/pages/PoolingMap/rbac'
import { RackRouterDetail } from 'features/Rack/router'

/* edit menu disini */

export type SidebarConfigType = {
  name: string
  id: string
  link?: string
  icon?: string
  location_type?: string[]
  allow?: string[]
  allowAll?: boolean
  pageID?: string
  multipleLinkWithPageID?: {
    link: string
    pageID: string
  }[]
  subMenu?: Omit<SidebarConfigType[number], 'subMenu'>[]
}[]

const config: SidebarConfigType = [
  {
    name: 'Dashboard',
    id: 'dashboard',
    link: dashboard,
    icon: 'dashboard',
    allow: allowAccess.dashboard,
  },
  {
    name: 'Bulk Upload',
    id: 'bulkUpload',
    link: bulkUploadV2,
    icon: 'upload',
    allow: allowAccess.bulkUploadV2,
    allowAll: true,
  },
  {
    name: 'Kategori & Posisi',
    id: 'category_and_position',
    link: category,
    icon: 'category',
    allow: allowAccess.category_and_position,
    subMenu: [
      {
        name: 'Kategori',
        id: 'category',
        link: category,
        allow: allowAccess.category,
      },
      {
        name: 'UOM',
        id: 'UOM',
        link: `${uom}`,
        allow: allowAccess.category,
      },
      {
        name: 'Set Posisi',
        id: 'set_position',
        link: setPosition,
        allow: allowAccess.set_position,
      },
      {
        name: 'Katalog',
        id: 'catalogue',
        link: catalogue,
        allow: allowAccess.catalogue,
      },
      {
        name: 'Pin Produk',
        id: 'pin_product',
        link: PinProductRouter.path,
        pageID: PinProductRouter.rbacPageID,
        allow: PinProductRouter.allowedRoles,
      },
      {
        name: 'Dynamic Channel',
        id: 'dynamic_channel',
        link: DynamicChannelRouter.path,
        allow: DynamicChannelRouter.allowedRoles,
      },
      {
        name: 'Homepage Slotting',
        id: 'homepage_slotting',
        link: HomepageSlottingRouter.path,
        allow: HomepageSlottingRouter.allowedRoles,
      },
    ],
  },
  {
    name: 'Dynamic LP',
    id: 'dynamic-landing',
    link: dynamicLandingList,
    icon: 'microsite',
    allow: allowAccess.dynamicLandingPage,
  },
  {
    name: 'Produk & Stok',
    id: 'product_and_stock',
    link: product,
    icon: 'product',
    subMenu: [
      {
        name: 'Product',
        id: 'product',
        link: product,
        allow: allowAccess.product,
      },
      {
        name: 'Brand Master',
        id: 'brand-master',
        link: brandMaster,
        allow: allowAccess.brandMaster,
      },
      {
        name: 'Hub',
        id: 'hub',
        link: hub,
        location_type: ['HUB'],
        allow: allowAccess.hub,
      },
      {
        name: 'Warehouse',
        id: 'warehouse',
        link: warehouse,
        location_type: ['WAREHOUSE'],
        allow: allowAccess.warehouse,
      },
      {
        name: 'Vendor',
        id: 'vendor',
        link: VendorListRouter.path,

        allow: VendorListRouter.allowedRoles,
      },
      {
        name: 'Vendor Brand Group',
        id: 'vendorBrandGroup',
        link: vendorBrandGrouping,

        allow: allowAccess.vendorBrandGrouping,
      },
      {
        name: 'Product Assortment',
        id: 'productAssortment',
        link: productAssortment,

        allow: allowAccess.productAssortment,
      },
      {
        name: 'Product Label',
        id: 'productLabel',
        link: productLabel,

        allow: allowAccess.productLabel,
      },
      {
        name: 'Conversion Rule',
        id: 'conversionRule',
        link: ProductRouterDetail.ConversionRuleRouter.path,

        allow: ProductRouterDetail.ConversionRuleRouter.allowedRoles,
      },
      {
        name: 'Price Tag',
        id: 'priceTag',
        link: ProductRouterDetail.PriceTagRouter.path,

        allow: ProductRouterDetail.PriceTagRouter.allowedRoles,
      },
    ],
  },
  {
    name: 'Varian',
    id: 'variant',
    link: VariantModuleRouter.path,
    icon: 'product',

    allow: VariantModuleRouter.allowedRoles,
    subMenu: [
      {
        name: 'Master Varian',
        id: 'master-variant',
        link: ProductRouterDetail.MasterVariantRouter.path,

        allow: ProductRouterDetail.MasterVariantRouter.allowedRoles,
      },
      {
        name: 'Produk Varian',
        id: 'product-variant',
        link: ProductRouterDetail.ProductVariantRouter.path,

        allow: ProductRouterDetail.ProductVariantRouter.allowedRoles,
      },
    ],
  },
  {
    name: 'Modifier',
    id: 'modifier',
    link: ProductRouterDetail.ModifierRouter.path,
    icon: 'product',

    allow: [
      ...ProductRouterDetail.ModifierRouter.allowedRoles,
      ...ProductRouterDetail.ModifierAddRouter.allowedRoles,
    ],
    subMenu: [
      {
        name: 'Semua Modifier',
        id: 'modifier-all',
        link: ProductRouterDetail.ModifierRouter.path,

        allow: ProductRouterDetail.ModifierRouter.allowedRoles,
      },
      {
        name: 'Hubungan Modifier',
        id: 'modifier-connection',
        link: ProductRouterDetail.ModifierConnectionRouter.path,

        allow: ProductRouterDetail.ModifierConnectionRouter.allowedRoles,
      },
    ],
  },
  {
    name: 'Campaign & Diskon',
    id: 'campaign',
    link: campaign,
    icon: 'campaign',

    allow: [
      ...CampaignAndDiscountRouterDetail.CampaignListRouter.allowedRoles,
      ...CampaignAndDiscountRouterDetail.CampaignSegmentListRouter.allowedRoles,
    ],
    subMenu: [
      {
        name: 'Campaign',
        id: 'campaign_sub',
        link: campaign,

        allow: CampaignAndDiscountRouterDetail.CampaignListRouter.allowedRoles,
      },
      {
        name: 'Segmen User',
        id: 'user-segment',
        link: CampaignSegmentListRouter.path,

        allow: CampaignAndDiscountRouterDetail.CampaignSegmentListRouter.allowedRoles,
      },
    ],
  },
  {
    name: 'Atur Jadwal',
    id: 'staff-driver',
    link: schedule,
    icon: 'staff-driver',
    allow: combineScheduleRolesAccess,
    subMenu: [
      {
        name: 'Jadwal Kerja',
        id: 'schedule-staff',
        link: ScheduleRouter.path,

        allow: ScheduleRouter.allowedRoles,
      },
      {
        name: 'Master Approval',
        id: 'schedule-master-approval',
        link: MasterApprovalRouter.path,

        allow: MasterApprovalRouter.allowedRoles,
      },
      {
        name: 'Riwayat Absensi',
        id: 'schedule-attendance',
        link: AttendanceRouter.path,

        allow: AttendanceRouter.allowedRoles,
      },
      {
        name: 'Master Shift',
        id: 'schedule-master-shift',
        link: MasterShiftRouter.path,

        allow: MasterShiftRouter.allowedRoles,
      },
    ],
  },
  {
    name: 'Payroll',
    id: 'payroll',
    link: masterPayroll,
    icon: 'payroll',

    allow: MasterPayrollRouter.allowedRoles,
    subMenu: [
      {
        name: 'Master Payroll',
        id: 'master-payroll',
        link: MasterPayrollRouter.path,

        allow: MasterPayrollRouter.allowedRoles,
      },
    ],
  },
  {
    name: 'Banner & Ticker',
    id: 'banner',
    link: banner,
    icon: 'flag',

    allow: allowAccess.banner_and_ticker,
    subMenu: [
      {
        name: 'Banner Regular',
        id: 'banner sub',
        link: BannerRouter.path,

        allow: BannerRouter.allowedRoles,
      },
      {
        name: 'Banner Thematic',
        id: 'bannerThematic',
        link: BannerThematicRouter.path,

        allow: BannerThematicRouter.allowedRoles,
      },
      {
        name: 'Ticker',
        id: 'ticker',
        link: ticker,

        allow: allowAccess.ticker,
      },
      {
        name: 'Promo Referral Widget',
        id: 'promo-referral-widget',
        link: promoReferralWidget,

        allow: allowAccess.promoReferralWidget,
      },
    ],
  },
  {
    name: 'Lokasi',
    id: 'location',
    link: location,
    icon: 'location',

    subMenu: [
      {
        name: 'Lokasi',
        id: 'location',
        link: location,

        pageID: locationRBAC.pageID,
      },
      {
        name: 'Lokasi Preview',
        id: 'locationPreview',
        link: locationPreview,

        pageID: locationPreviewRBAC.pageID,
      },
      {
        name: 'Pengaturan Fitur',
        id: 'flagManagement',
        link: flagManagement,
        icon: 'flag',

        pageID: featureFlagRBAC.pageID,
      },
      {
        name: 'Service Level',
        id: 'service_level',
        link: serviceLevel,

        pageID: serviceLevelRBAC.pageID,
      },
    ],
  },
  {
    name: 'Akun',
    id: 'account',
    link: admin,
    icon: 'group',

    subMenu: [
      {
        name: 'Admin Access',
        id: 'admin-access',

        multipleLinkWithPageID: [
          {
            link: AdminAccessRouter.path.replace(':id', 'user'),
            pageID: AdminAccessRouter.multiplePageID?.pageIDmatchWithKeySegments.user || '',
          },
          {
            link: AdminAccessRouter.path.replace(':id', 'page'),
            pageID: AdminAccessRouter.multiplePageID?.pageIDmatchWithKeySegments.page || '',
          },
          {
            link: AdminAccessRouter.path.replace(':id', 'roles'),
            pageID: AdminAccessRouter.multiplePageID?.pageIDmatchWithKeySegments.roles || '',
          },
        ],
      },
      {
        name: 'Admin',
        id: 'admin',
        link: admin,

        allow: allowAccess.admin,
      },
      {
        name: 'Hub Staff',
        id: 'driver',
        link: hubStaff,

        allow: allowAccess.driverHubStaff,
      },
      {
        name: 'Central Warehouse',
        id: 'central_warehouse',
        link: centralWarehouseStaff,

        allow: allowAccess.warehouseStaff,
      },
      {
        name: 'MP Staff',
        id: 'mpStaff',
        link: MPStaffRouter.path,
        allow: MPStaffRouter.allowedRoles,
      },
      {
        name: 'MP Planning',
        id: 'mp_planning',
        link: mpPlanning,

        allow: allowAccess.driver,
      },
      {
        name: 'MP Budget',
        id: 'mp_budget',
        link: MPBudgetRouter.path,

        allow: MPBudgetRouter.allowedRoles,
      },
      {
        name: 'Customer',
        id: 'buyer',
        link: BuyerRouter.path,

        allow: BuyerRouter.allowedRoles,
      },
      {
        name: 'Auth Control',
        id: 'AuthControll',

        allow: AuthenticationControllRouter.allowedRoles,
        link: AuthenticationControllRouter.path,
      },
    ],
  },

  {
    name: 'Purchase Order',
    id: 'purchase_order',
    link: purchaseOrderLite,
    icon: 'box',

    allow: allowAccess.module_purchase_order,
    subMenu: [
      {
        name: 'Create PO',
        id: 'create_purchase_order',
        link: purchaseOrderLite,
        location_type: [
          'HUB',
          'WAREHOUSE',
          'ASTRO OFFLINE',
          'MEGAHUB 2.0',
          'VIRTUAL',
          'OFFICE',
          'VENDING MACHINE',
          'MEGAHUB',
          'DIGITAL GOODS',
        ],
        allow: allowAccess.create_purchase_order_lite,
      },
      {
        name: 'Inbound - Hub',
        id: 'inbound_hub_po',
        link: `${inboundPo}?locationType=hub,${encodeURI('astro offline')}&status=`,
        location_type: ['HUB', 'ASTRO OFFLINE'],
        allow: allowAccess.inbound_hub_purchase_order_lite,
      },
      {
        name: 'Inbound - WRHS',
        id: 'inbound_warehouse_po',
        link: `${inboundPo}?locationType=warehouse&status=`,
        location_type: ['WAREHOUSE'],
        allow: allowAccess.inbound_warehouse_purchase_order_lite,
      },
      {
        name: 'Inbound - Megahub 2.0',
        id: 'inbound_megahub_2.0_po',
        link: `${inboundPo}?locationType=megahub 2.0&status=`,
        location_type: ['MEGAHUB 2.0'],
        allow: allowAccess.inbound_warehouse_purchase_order_lite,
      },
      {
        name: 'PO Approval',
        id: 'purchase_order_approval',
        link: `${purchaseOrderLiteApproval}?status=PENDING_APPROVAL_CREATED,PENDING_APPROVAL_DELIVERY`,
        location_type: ['HUB', 'WAREHOUSE'],
        allow: allowAccess.purchase_order_lite_approval,
      },
      {
        name: 'Reorder List SC',
        id: 'reorder_list_sc',
        link: `${reorderList}?role=sc`,

        allow: allowedRolesReorderListSc,
      },
      {
        name: 'Reorder List Sourcing',
        id: 'reorder_list_sourcing',
        link: `${reorderList}?role=sourcing`,

        allow: allowedRolesReorderListSourcing,
      },
      // {
      //   name: 'Purchase Request',
      //   id: 'purchase_request',
      //   link: purchaseRequest,
      //
      //   allow: allowAccess.purchase_request,
      // },
      {
        name: 'Direct Putaway',
        id: 'direct_putaway',
        link: directputaway,

        allow: allowAccess.direct_putaway,
      },
    ],
  },
  // {
  //   name: 'Fresh Purchase Order',
  //   id: 'fresh_purchase_order',
  //   link: freshPurchaseOrderList,
  //   icon: 'fresh',
  //
  //   allow: allowAccess.fresh_purchase_order,
  //   subMenu: [
  //     {
  //       name: 'FPO List',
  //       id: 'fresh_purchase_order_list',
  //       link: freshPurchaseOrderList,
  //
  //       allow: allowAccess.fresh_purchase_order_list,
  //     },
  //     {
  //       name: 'Inbound FPO',
  //       id: 'fresh_purchase_order_inbound',
  //       link: freshPurchaseOrderInbound,
  //
  //       allow: allowAccess.fresh_purchase_order_inbound,
  //     },
  //     {
  //       name: 'Trimming & Packing',
  //       id: 'fresh_purchase_order_trimming_and_packing',
  //       link: freshPurchaseOrderTrimmingandPacking,
  //
  //       allow: allowAccess.fresh_purchase_order_trimming_and_packing,
  //     },
  //   ],
  // },
  {
    name: 'Stok Opname',
    id: 'stock-opname',
    link: stockOpnameList,
    icon: 'stock-opname',

    allow: allowAccess.stock_opname,
    subMenu: [
      {
        name: 'Stock Opname',
        id: 'stock-opname-list',
        link: stockOpnameList,

        allow: allowAccess.stock_opname_list,
      },
      {
        name: 'Adjustment',
        id: 'stock-opname-adjustment',
        link: stockOpnameAdjustment,

        allow: allowAccess.stock_opname_adjustment,
      },
    ],
  },
  {
    name: 'Inbound',
    id: 'Inbound',
    link: inboundSo,
    icon: 'inBox',

    allow: allowAccess.inbound,
    subMenu: [
      {
        name: 'Inbound SO WRHS',
        id: 'inboundv2',
        link: `${inboundSo}?type=warehouse`,

        allow: allowAccess.inbound_warehouse,
      },
      {
        name: 'Inbound SO Hub',
        id: 'inboundvhub2',
        link: `${inboundSo}?type=${hubPageLocationType.toString()}`,

        allow: allowAccess.inbound_hub,
      },
      {
        name: 'Generate Package ID',
        id: 'package_id_generator',
        link: packageIDGenerator,

        allow: allowAccess.generate_package_id,
      },
      {
        name: 'Generate Koli ID',
        id: 'koli_id_generator',
        link: koliIDGenerator,

        allow: allowAccess.generate_koli_id,
      },
      {
        name: 'SO Approval',
        id: 'supply_order_approval',
        link: supplyOrderApproval,

        allow: allowAccess.soApproval,
      },
    ],
  },
  {
    name: 'Outbound',
    id: 'Outbound',
    link: purchaseOrderLite,
    icon: 'outbound',

    allow: allowAccess.outbound_supply_order,
    subMenu: [
      {
        name: 'Create SO',
        id: 'create_supply_order_grand',
        link: `${supplyOrder}?created=true`,

        allow: allowAccess.create_supply_order,
      },
      {
        name: 'Supply Order',
        id: 'create_supply_order_SO',
        link: `${supplyOrder}?created=false#SO`,

        allow: allowAccess.outbound_supply_order_dashboard,
      },
      {
        name: 'Tugas Replenish',
        id: 'replenishmentTask',

        link: SupplyOrderRouterDetail.ReplenishmentTaskRouter.path,
        allow: SupplyOrderRouterDetail.ReplenishmentTaskRouter.allowedRoles,
      },
      {
        name: 'Jadwal Replenish',
        id: 'replenishmentSchedule',

        link: SupplyOrderRouterDetail.ReplenishmentScheduleRouter.path,
        allow: SupplyOrderRouterDetail.ReplenishmentScheduleRouter.allowedRoles,
      },
      {
        name: 'Koli Hilang di Staging',
        id: 'koli_lost',
        link: koliLost,
        allow: allowAccess.koliLost,
      },
      {
        name: 'Xdock',
        id: 'xdock',
        link: SupplyOrderRouterDetail.XdockRouter.path,
        allow: SupplyOrderRouterDetail.XdockRouter.allowedRoles,
      },
      {
        name: 'Limit SKU per SO',
        id: 'limit_sku',
        link: SupplyOrderRouterDetail.LimitSKURouter.path,
        allow: SupplyOrderRouterDetail.LimitSKURouter.allowedRoles,
      },
    ],
  },
  {
    name: 'Kitting De-Kitting',
    id: 'Kitting De-Kitting',
    link: kitting,
    icon: 'outbound',

    allow: allowAccess.kitting_de_kitting,
    subMenu: [
      {
        name: 'Kitting De-Kitting',
        id: 'Kitting De-Kitting',
        link: kitting,

        allow: allowAccess.kitting_de_kitting,
      },
      {
        name: 'BoM',
        id: 'bom',
        link: bom,
        icon: 'bom',

        allow: allowAccess.kitting_de_kitting,
      },
    ],
  },

  {
    name: 'Pesanan',
    id: 'order',
    link: ``,
    icon: 'cart',

    allow: allowAccess.order,
    subMenu: [
      {
        name: 'Pesanan Berlangsung',
        id: 'order',
        link: OnGoingRoutes.newOrder,

        allow: allowAccess.pesanan_berlangsung,
      },
      {
        name: 'Pooling Map',
        id: 'order',
        link: poolingMap,
        pageID: PoolingMapRolesRBAC.pageID,
      },
      {
        name: 'Semua Pesanan',
        id: 'all_order',
        link: allorder,

        allow: allowAccess.all_order,
      },
      {
        name: 'Buat Pesanan',
        id: 'create_order',
        link: createOrder,

        allow: allowAccess.createOrder,
      },
      {
        name: 'Driver Monitoring',
        id: 'dashboard_monitor',

        link: OrderRouterDetail.DriverMonitoringRouter.path,
        allow: OrderRouterDetail.DriverMonitoringRouter.allowedRoles,
      },
    ],
  },
  {
    name: 'Payment',
    id: 'refund_and_disburse',
    link: refund,
    icon: 'refund',

    allow: allowAccess.refundAndDisburse,
    subMenu: [
      {
        name: 'Refund',
        id: 'refund',
        link: refund,

        allow: allowAccess.refund,
      },
      {
        name: 'Disbursement',
        id: 'disbursement',
        link: disbursement,

        allow: allowAccess.disbursement,
      },
      {
        name: 'Approval Inject',
        id: 'approvalInjection',
        link: ApprovalInjectionRouter.path,

        allow: ApprovalInjectionRouter.allowedRoles,
      },
      {
        name: 'Payment Gateway',
        id: 'paymentGateway',
        link: PaymentGatewayAdminRouter.path,

        allow: PaymentGatewayAdminRouter.allowedRoles,
      },
    ],
  },
  {
    name: 'Promotional',
    id: 'promo',
    link: promotion,
    icon: 'promotion',

    allow: allowAccess.promotion,
    subMenu: [
      {
        name: 'Flash Sale',
        id: 'flash_sale',
        link: PromotionRouterDetail.FlashSaleRouter.path,

        allow: PromotionRouterDetail.FlashSaleRouter.allowedRoles,
      },
      {
        name: 'Milestone',
        id: 'milestone',
        link: MilestoneListRouter.path,

        allow: MilestoneListRouter.allowedRoles,
      },
      {
        name: 'Promo',
        id: 'promo',
        link: promotion,

        allow: allowAccess.add_promotion_voucher,
      },
      {
        name: 'Kombo Hemat',
        id: 'flexi_combo',
        link: PromotionRouterDetail.FlexiComboRouter.path,
        allow: PromotionRouterDetail.FlexiComboRouter.allowedRoles,
      },
      {
        name: 'Referral Mission',
        id: 'referral_mission_list',
        link: referralMissionList,

        allow: allowAccess.promotion_referral_setting,
      },
      {
        name: 'Referral Setting',
        id: 'referral_setting',
        link: referralSetting,

        allow: allowAccess.promotion_referral_setting,
      },
      {
        name: 'Referral VIP',
        id: 'referral_vip',
        link: referralVIPList,

        allow: allowAccess.promotion_referral_vip_list,
      },
      {
        name: 'GWP Setting',
        id: 'gift_with_purchase_setting',
        link: gwp,
        allow: allowAccess.gift_with_purchase,
      },
      {
        name: 'Purchase with Purchase',
        id: 'purchase_with_purchase_setting',
        link: pwp,
        allow: allowAccess.purchase_with_purchase,
      },
    ],
  },
  {
    name: 'Unique Voucher',
    id: 'unique_voucher',
    link: uniqueVoucherList,
    icon: 'category',

    allow: allowAccess.unique_voucher,
    subMenu: [
      {
        name: 'Voucher List',
        id: 'unique_voucher_list',
        link: uniqueVoucherList,

        allow: allowAccess.unique_voucher_list,
      },
      {
        name: 'Campaign List',
        id: 'unique_voucher_campaign_list',
        link: uniqueVoucherCampaignList,

        allow: allowAccess.unique_voucher_campaign_list,
      },
    ],
  },
  {
    name: 'Capacity Handler',
    id: 'capacity_handler',
    link: loadingDock,
    icon: 'capacity',

    allow: [...allowAccess.capacityHandler, ...allowAccess.inboundSchedule],
    subMenu: [
      {
        name: 'Master Loading Dock',
        id: 'loading_dock',
        link: loadingDock,

        allow: allowAccess.capacityHandler,
      },
      {
        name: 'Master Timeslot',
        id: 'timeslot',
        link: timeSlot,

        allow: allowAccess.capacityHandler,
      },
      {
        name: 'Jadwal PO Sourcing',
        id: 'po_sourching_schedule',
        link: inboundSchedule,

        allow: allowAccess.inboundSchedule,
      },
    ],
  },
  {
    name: 'Fraud',
    id: 'fraud',
    link: fraudSetting,
    icon: 'upload',

    allow: allowAccess.fraud,
    subMenu: [
      {
        name: 'Auto Cancel Rule List',
        id: 'Watchtower Rule List',
        link: WatchTowerAutoCancelRuleListRouter.path,

        allow: WatchTowerAutoCancelRuleListRouter.allowedRoles,
      },
      {
        name: 'Watchtower Rule List',
        id: 'Watchtower Rule List',
        link: fraudWatchTowerRule,

        allow: allowAccess.fraud_watch_tower_rule,
      },
      {
        name: 'Query Rule List',
        id: 'Query Rule List',
        link: fraudWatchTowerQueryRule,

        allow: allowAccess.fraud_watch_tower_query_rule,
      },
      {
        name: 'Pengaturan Fraud',
        id: 'Pengaturan Fraud',
        link: fraudSetting,

        allow: allowAccess.fraud_setting,
      },
      {
        name: 'Fraud Detection',
        id: 'fraud_detection',
        link: FraudRouterDetail.FraudDetectionRouter.path,

        allow: FraudRouterDetail.FraudDetectionRouter.allowedRoles,
      },
      {
        name: 'Blacklist/Whitelist',
        id: 'fraud_black_and_white_list',
        link: fraudBlackAndWhiteList,

        allow: allowAccess.fraud_black_and_white_list,
      },
      {
        name: 'Referral Fraud',
        id: 'fraud_referral',
        link: fraudReferral,

        allow: allowAccess.fraud_referral,
      },
      {
        name: 'Parameter List',
        id: 'fraud_parameter',
        link: fraudWatchTowerParameters,

        allow: allowAccess.fraud_watch_tower_parameters,
      },
      {
        name: 'User Fraud Status',
        id: UserFraudStatusRouter.name,
        link: UserFraudStatusRouter.path,

        allow: UserFraudStatusRouter.allowedRoles,
      },
      {
        name: 'Area Detection',
        id: WatchtowerAreaDetectionRouter.name,
        link: WatchtowerAreaDetectionRouter.path,

        allow: WatchtowerAreaDetectionRouter.allowedRoles,
      },
    ],
  },
  {
    name: 'Loyalty',
    id: 'loyalty',
    link: coinEarned,
    icon: 'setting',

    allow: allowAccess.loyalty,
    subMenu: [
      {
        name: 'Riwayat Koin',
        id: 'Coin History',
        link: coinHistory,

        allow: allowAccess.loyalty_coin_history,
      },
      {
        name: 'Koin di Dapat',
        id: 'Koin_di_Dapat',
        link: coinEarned,

        allow: allowAccess.loyalty_coin_earned,
      },
      {
        name: 'Koin di Tukar',
        id: 'Koin di Tukar',
        link: coinExchange,

        allow: allowAccess.loyalty_coin_exchange,
      },
      {
        name: 'Aktivitas',
        id: 'Aktivitas',
        link: loyaltyActivity,

        allow: allowAccess.loyalty_activity,
      },
      {
        name: 'Voucher',
        id: 'Voucher',
        link: loyaltyVoucher,

        allow: allowAccess.loyalty_voucher,
      },
      {
        name: 'Campaign Loyalty',
        id: 'campaign_loyalty',
        link: campaignLoyalty,

        allow: allowAccess.loyalty_voucher,
      },
      {
        name: 'Widget',
        id: 'widget',
        link: LoyaltyRouterDetail.LoyaltyWidgetRouter.path,

        allow: LoyaltyRouterDetail.LoyaltyWidgetRouter.allowedRoles,
      },
    ],
  },
  {
    name: 'Pengaturan',
    id: 'setting',
    link: setting,
    icon: 'setting',

    allow: allowAccess.setting,
    subMenu: [
      {
        name: 'Order Minimum',
        id: 'Order Minimum',
        link: setting,

        allow: allowAccess.setting,
      },
      {
        name: 'Order Detail',
        id: 'Order Detail',
        link: orderDetail,

        allow: allowAccess.setting,
      },
    ],
  },
  {
    name: 'Rak',
    id: 'rak',
    link: rack,
    icon: 'rack',
    allow: allowAccess.rak,
    subMenu: [
      {
        name: 'Master Rak',
        id: 'master_rak',
        link: rack,
        allow: allowAccess.rak,
      },
      {
        name: 'Master Area',
        id: 'master_area',
        link: RackRouterDetail.AreaListRouter.path,
        allow: RackRouterDetail.AreaListRouter.allowedRoles,
      },
    ],
  },
  {
    name: 'Midmile',
    id: 'midmile',
    link: routeAndSchedule,
    icon: 'rack',
    allow: allowAccess.routeMaster,
    subMenu: [
      {
        name: 'Rute & Jadwal',
        id: 'route_schedule',
        link: routeAndSchedule,

        allow: allowAccess.routeMaster,
      },
    ],
  },
  {
    name: 'HCU',
    id: 'hcu',
    link: hcu,
    icon: 'group',
    allow: allowAccess.all_order,
  },

  {
    name: 'A/B Testing',
    id: 'ABAdminList',
    link: ABAdminRouterDetail.ABAdminLoginRouter.path,
    icon: 'group',
    allow: ABAdminRouterDetail.ABAdminLoginRouter.allowedRoles,
  },
  {
    name: 'Ticket Scanner',
    id: 'TicketScanner',
    link: TicketScannerRouter.path,
    icon: 'rack',

    allow: TicketScannerRouter.allowedRoles,
  },

  {
    name: 'SKP',
    id: 'skp',
    icon: 'promotion',

    allow: allowAccess.skp,
    subMenu: [
      {
        name: 'Sourcing',
        id: 'skp_sourcing',
        link: skpSourcing,

        allow: allowAccess.skp_sourcing,
      },
      {
        name: 'Finance',
        id: 'skp_finance',
        link: skpFinance,

        allow: allowAccess.skp_finance,
      },
      {
        name: 'Campaign',
        id: 'skp_campaign',
        link: CampaignRouter.path,

        allow: CampaignRouter.allowedRoles,
      },
      {
        name: 'Internal / Private Label',
        id: 'skp_internal',
        link: InternalSKPRouter.path,

        allow: InternalSKPRouter.allowedRoles,
      },
      {
        name: 'Syarat Ketentuan',
        id: 'terms_and_condition_skp',
        link: skpTermsAndConditions,

        allow: allowAccess.terms_and_condition_skp,
      },
    ],
  },

  {
    name: 'SO Automation',
    id: 'so_automation',
    icon: 'setting',

    allow: allowAccess.so_automation,
    subMenu: [
      {
        name: 'SO Parameters',
        id: 'so_parameters',
        link: SOAutomationRouter.SOParametersRouter.path,

        allow: SOAutomationRouter.SOParametersRouter.allowedRoles,
      },
      {
        name: 'SO Schedules',
        id: 'so_schedules',
        link: SOAutomationRouter.SOSchedulesRouter.path,

        allow: SOAutomationRouter.SOSchedulesRouter.allowedRoles,
      },
      {
        name: 'SO Reports',
        id: 'so_reports',
        link: SOAutomationRouter.SOReportsRouter.path,

        allow: SOAutomationRouter.SOReportsRouter.allowedRoles,
      },
    ],
  },
]

export default config
