/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/naming-convention */

import {
  createAxiosInstance,
  AxiosError,
  AxiosResponse,
  withImageService,
} from '@astronautsid/wpe-utils'

import { axiosErrorLoggerInterceptor } from 'config/axiosLogger'
import { getTokenCookies } from 'config/cookies'

const {
  REACT_APP_BASE_URL_API_IMAGE_SERVICE,
  REACT_APP_NAME,
  REACT_APP_SYSTEM_MAINTENANCE_URL,
  REACT_APP_MAINTENANCE,
} = process.env

const token = getTokenCookies()

const handleRejected = (error: AxiosError) => {
  if (error?.request.status === 302 || REACT_APP_MAINTENANCE === 'true') {
    window.location.assign(`${REACT_APP_SYSTEM_MAINTENANCE_URL}?callback=${window.location.href}`)
  }
  axiosErrorLoggerInterceptor(error)

  return Promise.reject(error)
}

const axiosInstanceImageService = createAxiosInstance(
  {
    baseURL: REACT_APP_BASE_URL_API_IMAGE_SERVICE,
    headers: {
      'X-Service-Name': REACT_APP_NAME,
      Authorization: `Bearer ${token}`,
    },
  },
  {
    interceptorRequest: null,
    interceptorResponse: (response: AxiosResponse) => {
      if (REACT_APP_MAINTENANCE === 'true') {
        window.location.assign(
          `${REACT_APP_SYSTEM_MAINTENANCE_URL}?callback=${window.location.href}`,
        )
      }
      return response
    },
    interceptorResponseReject: null,
    interceptorRequestReject: handleRejected,
  },
)

export const {
  ServiceCustomerImageRules,
  ServiceCustomerImageUpload,
  ServiceInternalImageRules,
  ServiceInternalImageUpload,
} = withImageService(axiosInstanceImageService)

export default axiosInstanceImageService
