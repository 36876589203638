import { ImageVariantIdType, ImageUploadPayloadType } from '@astronautsid/wpe-utils'

import AxiosInstance from 'config/apiServiceApiGateway'
import { ServiceInternalImageRules, ServiceInternalImageUpload } from 'config/apiServiceImage'

export type ShiftNameType =
  | 'Pagi'
  | 'Siang'
  | 'Malam'
  | 'Izin'
  | 'Off Day'
  | 'Lembur Hari Off'
  | 'Rotasi Staff'
  | 'Custom'

export type ShiftUnionType = 'custom' | 'onleave' | 'off' | 'rotation' | 'emergency'

export interface ApprovalInterface {
  id: number
  issue_notes: string
  status: number
  status_notes: string
}

export interface AttendanceInterface {
  id: number
  schedule_id: number
  description: string
  shift_name: ShiftUnionType
  checkin_date: string
  precheckin_time: string
  checkin_time: string
  checkin_img: string
  checkin_note: {
    code: string
    description: string
  }
  checkin_note_img: string
  checkout_date: string
  precheckout_time: string
  checkout_time: string
  checkout_img: string
  checkout_note: {
    code: string
    description: string
    category: string
  }
  checkout_note_img: string
  is_full: boolean
  is_late: boolean
  is_early: boolean
  is_alpha: boolean
  is_leave: boolean
  duration: number
  overtime: number
  rotation_location_id: number
  rotation_location_name: string
  image_url: string
  leave_notes: string
  location_id: number
  location_name: string
  approvals: ApprovalInterface[]
  emergency_rotation: AttendanceInterface[]
}

export interface ScheduleInterface {
  schedule_id: number
  description: ShiftNameType
  start_date: string
  start_time: string
  end_date: string
  end_time: string
  shift_name: ShiftUnionType
  overtime: number
  active: boolean
  note: string
  role: string
  attachment_url: string
  location_id: number
  location_name: string
  location_type: string
  rotation_location_id: number
  rotation_location_name: string
  is_checkin: boolean
  approval: ApprovalInterface[]
  emergency_rotation: ScheduleInterface[]
}

export interface GetScheduleListAPIInterface {
  data: {
    staff_id: number
    full_name: string
    location_id: number
    location_name: string
    role: string
    is_rotation_staff: boolean
    schedule: ScheduleInterface[]
  }[]
  pagination: SchedulePaginationInterface
}

export interface SchedulePaginationInterface {
  number_of_elements: number
  page_index: number
  page_size: number
  total_pages: number
  total_elements: number
}

export interface GetAttendanceListServiceInterface {
  data: {
    staff_id: number
    full_name: string
    location_id: number
    location_name: string
    role: string
    attendance: AttendanceInterface[]
  }[]
  pagination: SchedulePaginationInterface
}

export interface GetSummaryScheduleServicesInterface {
  data: {
    desc: string
    end_time: string
    shift_name: string
    staff_total: number
    start_time: string
    detail: {
      date: string
      count: number
    }[]
  }[]
}

export interface GetStaffDetailAPIInterface {
  data: {
    supervisor: string
    driver_id: number
    full_name: string
    location_id: 1
    location_name: string
    role: string
    phone_number: string
    date_join: Date
    active: boolean
  }
}

export interface GetStaffDetailAttendanceServiceInterface {
  data: {
    id: number
    staff_id: number
    location: {
      id: number
      name: string
    }
    checkin_date: string
    precheckin_time: string
    checkin_time: string
    checkin_img: string
    checkin_note?: {
      code: string
      description: string
    }
    checkout_date: string
    precheckout_time: string
    checkout_time: string
    checkout_img: string
    checkout_note?: {
      code: string
      description: string
    }
    is_full: boolean
    is_late: boolean
    is_early: boolean
    duration: number
    durationStr: string
    overtime: number
    role: string
    emergency_rotation: AttendanceInterface[]
  }
}

export interface GetAttendanceHistoryServiceInterface {
  data: {
    id: number
    attendance_id: number
    staff_id: number
    location_id: number
    checkin_date: string
    email: string
    update: string
    changes_date: string
    changes_time: string
    notes: string
  }[]
}

export interface GetShiftListAPIInterface {
  data: {
    location_id: number
    shift_name: ShiftUnionType
    description: string
    start_time: string
    end_time: string
    is_normal_shift: boolean
    is_note_required: boolean
    is_attachment_required: boolean
    is_custom_time: boolean
  }[]
}

export interface GetAttendanceNotesInterface {
  data: {
    code: string
    description: string
  }[]
}

export type PostLeaverPermissionCurrentDayType = {
  day_permit_file_url: string
  approval_ids: number[]
  location_id: number
  notes: string
  schedule_id: number
  staff_id: number
  created_by: string
  roles: string[]
}

export type UpdateSchedulePayloadType = {
  location_id: number
  start_date: string
  start_time: string
  end_date: string
  end_time: string
  shift_name: string
  staff_id: number
  over_time: string
  updated_by: string
  note: string
  description: string
  rotation_location_id: number
  role: string
  is_admin: boolean
  authority: string[]
  rotation_schedule_id: number
  schedule_file_url: string
  approval_ids?: number[]
}

export type CreateSchedulePayloadType = {
  schedule_file_url: string
  approval_ids?: number[]
  authority: string[]
  created_by: string
  description: string
  end_date: string
  end_time: string
  file_name: string
  is_admin: boolean
  location_id: number
  note: string
  over_time: string
  role: string
  rotation_location_id: number
  rotation_schedule_id: number
  shift_name: string
  staff_id: number
  start_date: string
  start_time: string
}

export type UpdateAttendancePayloadType = {
  staff_id: number
  location_id: number
  checkin_date?: string
  checkin_time?: string
  checkin_note?: string
  checkout_time?: string
  overtime: number
  checkin_time_change_notes?: string
  checkin_note_change_notes?: string
  checkout_time_change_notes?: string
  overtime_change_notes?: string
  updated_by: string
  roles: string[]
  approval_ids?: number[]
  checkin_file_url?: string
  checkout_file_url?: string
  checkin_note_file_url?: string
  checkout_note_file_url?: string
}

export const getScheduleListAPI = (params: {
  startDate?: string
  endDate?: string
  locationId?: number
  search?: string
  department?: string
  pageIndex?: number
}) =>
  AxiosInstance.Get<GetScheduleListAPIInterface, true>({
    url: '/staff-management/internal/:version/schedule/complete',
    version: 'v1',
    params: {
      ...params,
      page_size: 20,
    },
    convertRequest: true,
  })

export const getStaffDetailAPI = (urlParams: { id: number }) =>
  AxiosInstance.Get<GetStaffDetailAPIInterface, true>({
    url: '/staff-management/internal/:version/schedule/staff-detail/:id',
    version: 'v1',
    urlParams,
  })

export const getAttendanceListService = (params: {
  startDate?: string
  endDate?: string
  locationId?: number
  search?: string
  department?: string
  pageIndex?: number
}) =>
  AxiosInstance.Get<GetAttendanceListServiceInterface, true>({
    url: '/staff-management/internal/:version/attendance/detail',
    version: 'v1',
    params: {
      ...params,
      page_size: 20,
    },
    convertRequest: true,
  })

export const getStaffDetailAttendanceService = ({
  id,
  date,
  locationId,
}: {
  id: number
  date: string
  locationId: number
}) =>
  AxiosInstance.Get<GetStaffDetailAttendanceServiceInterface, true>({
    url: 'staff-management/internal/:version/attendance/staff',
    version: 'v1',
    params: { staff_id: id, checkin_date: date, location_id: locationId },
  })

export const getAttendanceHistoryService = (params: {
  staff_id: number
  checkin_date: string
  location_id: number
}) =>
  AxiosInstance.Get<GetAttendanceHistoryServiceInterface, true>({
    url: 'staff-management/internal/:version/attendance/history',
    version: 'v1',
    params,
  })

export const getExportedSchedule = (data: {
  month: string
  locationId: number
  year: string
  department: string
}) =>
  AxiosInstance.Post<{ file_url: 'string' }>({
    url: '/staff-management/internal/:version/schedule/export',
    version: 'v1',
    data,
    convertRequest: true,
  })

export const getExportedAttendance = (params: {
  startDate: string
  endDate: string
  locationId: number
  department: string
}) =>
  AxiosInstance.Get<{ file_url: 'string' }>({
    url: '/staff-management/internal/:version/attendance/export',
    version: 'v1',
    params,
    convertRequest: true,
  })

export const deleteLeavePermission = (urlParams: { scheduleId: string }) =>
  AxiosInstance.Delete({
    url: '/staff-management/internal/:version/attendance/:scheduleId',
    version: 'v1',
    urlParams,
  })

export const deleteSchedule = (id: number) =>
  AxiosInstance.Delete({
    url: '/staff-management/internal/:version/schedule/:id',
    version: 'v1',
    urlParams: { id },
  })

export const postCreateScheduleAPI = (data: CreateSchedulePayloadType) =>
  AxiosInstance.Post({
    url: '/staff-management/internal/v2/schedule',
    data,
  })

export const putUpdateScheduleAPI = (data: UpdateSchedulePayloadType, scheduleId: number) =>
  AxiosInstance.Put({
    url: `/staff-management/internal/v2/schedule/${scheduleId}`,
    data,
  })

export const putUpdateAttendanceAPI = (data: UpdateAttendancePayloadType, id: number) =>
  AxiosInstance.Put({
    url: `/staff-management/internal/v2/attendance/${id}`,
    data,
  })

export const postLeavePermissionCurrentDay = (data: PostLeaverPermissionCurrentDayType) =>
  AxiosInstance.Post({
    url: '/staff-management/internal/v2/attendance/day-permit',
    data,
  })

export const getAttendanceNotesService = () =>
  AxiosInstance.Get<GetAttendanceNotesInterface, true>({
    url: '/staff-management/internal/:version/attendance/notes',
    version: 'v1',
  })

export const getShiftListAPI = (params: { locationId: string }) =>
  AxiosInstance.Get<GetShiftListAPIInterface, true>({
    url: '/staff-management/internal/:version/shift/list',
    version: 'v1',
    params: {
      location_id: params.locationId,
    },
  })

export const getScheduleSummaryServices = (params: {
  startDate?: string
  endDate?: string
  locationId?: number
  search?: string
}) =>
  AxiosInstance.Get<GetSummaryScheduleServicesInterface, true>({
    url: '/staff-management/internal/:version/schedule/summary',
    version: 'v1',
    params,
    convertRequest: true,
    convertResponse: true,
  })

export const getHRISimageRules = (typeId: ImageVariantIdType) => ServiceInternalImageRules(typeId)

export const postHRISImage = (payload: ImageUploadPayloadType[]) =>
  ServiceInternalImageUpload(payload)
