import AxiosInstance from 'config/apiServiceApiGateway'

export interface GetMasterShiftServicesInterface {
  data: {
    id: number
    location_id: number
    shift_name: string
    start_time: string
    end_time: string
    is_note_required: boolean
    is_attachment_required: boolean
    is_custom_time: boolean
    is_normal_shift: boolean
    description: string
  }[]
}

interface CreateAndUpdateMasterShiftInterface {
  locationId: number
  shiftName: string
  startTime: string
  endTime: string
  isNoteRequired: boolean
  isAttachmentRequired: boolean
  isCustomTime: boolean
  isNormalShift: boolean
  createdBy?: string
  updatedBy?: string
}

export const getMasterShiftService = (params: { locationId: string }) =>
  AxiosInstance.Get<GetMasterShiftServicesInterface, true>({
    url: '/staff-management/internal/:version/shift/list',
    version: 'v1',
    params: {
      location_id: params.locationId,
      is_normal_shift: true,
    },
  })

export const createMasterShiftService = (data: CreateAndUpdateMasterShiftInterface) =>
  AxiosInstance.Post<GetMasterShiftServicesInterface, true>({
    url: '/staff-management/internal/:version/shift',
    version: 'v1',
    data,
    convertRequest: true,
  })

export const updateMasterShiftService = (data: CreateAndUpdateMasterShiftInterface, id?: number) =>
  AxiosInstance.Put<GetMasterShiftServicesInterface, true>({
    url: '/staff-management/internal/:version/shift/:id',
    version: 'v1',
    data,
    urlParams: { id },
    convertRequest: true,
  })
